import PropTypes from 'prop-types';
import React, { useEffect, useLayoutEffect } from 'react';
import { navigate } from 'gatsby';
import { Router } from '@reach/router';
import { useRecoilState } from 'recoil';

// Components
import Layout from 'layout';
import AdminNavbar from 'components/AdminNavbar';
import PrivateRoute from 'components/PrivateRoute';
// UI
import LoadingPage from 'ui/LoadingPage';
// Import from State
import { isLoggedIn as loginState, profileState, configurationState } from 'state';
// Auth Function
import { checkLoginStatus } from 'func/auth';

// Lazy - Account Routes
const ListAccounts = React.lazy(() => import('components/Admin/Accounts/List'));
const CreateAccount = React.lazy(() => import('components/Admin/Accounts/Create'));
const EditAccount = React.lazy(() => import('components/Admin/Accounts/Edit/EditParent'));
// Lazy - Companies Routes
const ListCompanies = React.lazy(() => import('components/Admin/Companies/List'));
const CreateCompany = React.lazy(() => import('components/Admin/Companies/Create'));
const EditCompany = React.lazy(() => import('components/Admin/Companies/Edit/EditParent'));
// Lazy - Configuration Route
const Configuration = React.lazy(() => import('components/Admin/Configuration'));
// Lazy Customistion Routes
const Customisation = React.lazy(() => import('components/Admin/Customisation/List'));
const CreateCustomisation = React.lazy(() => import('components/Admin/Customisation/Create'));
const EditCustomisation = React.lazy(() => import('components/Admin/Customisation/Edit/EditParent'));
// Lazy Legal Routes
const ListLegal = React.lazy(() => import('components/Admin/Legal/List'));
const CreateLegalReport = React.lazy(() => import('components/Admin/Legal/Create'));
const EditLegal = React.lazy(() => import('components/Admin/Legal/Edit/EditParent'));
// Lazy System Logs Routes
const ListSystemLogs = React.lazy(() => import('components/Admin/SystemLogs/List'));
const ViewSystemLogs = React.lazy(() => import('components/Admin/SystemLogs/View/ViewParent'));
// Lazy - User Routes
const ListUsers = React.lazy(() => import('components/Admin/Users/List'));
const CreateUser = React.lazy(() => import('components/Admin/Users/Create'));
const CreateAppUser = React.lazy(() => import('components/Admin/Users/Create/CreateAppUser'));
const EditUser = React.lazy(() => import('components/Admin/Users/Edit/EditParent'));
// Lazy - Veicle Types Routes
const ListVehicleTypes = React.lazy(() => import('components/Admin/VehicleTypes/List'));
const CreateVehicleType = React.lazy(() => import('components/Admin/VehicleTypes/Create'));
const EditVehicleType = React.lazy(() => import('components/Admin/VehicleTypes/Edit/EditParent'));
// Lazy - Quote Modifier Routes
const ListQuoteModifiers = React.lazy(() => import('components/Admin/QuoteModifier/List'));
const CreateQuoteModifiers = React.lazy(() => import('components/Admin/QuoteModifier/Create'));
const EditQuoteModifier = React.lazy(() => import('components/Admin/QuoteModifier/Edit/EditParent'));
// Lazy - Quote Modifier Routes
const ListAverageFare = React.lazy(() => import('components/Admin/AverageFare/List'));
const CreateAverageFare = React.lazy(() => import('components/Admin/AverageFare/Create'));
const EditAverageFare = React.lazy(() => import('components/Admin/AverageFare/Edit/EditParent'));
// Lazy - Custom Fields Routes
const ListCustomFields = React.lazy(() => import('components/Admin/CustomFields/List'));
const CreateCustomFields = React.lazy(() => import('components/Admin/CustomFields/Create'));
const EditCustomFields = React.lazy(() => import('components/Admin/CustomFields/Edit/EditParent'));

AdminFile.propTypes = {
  location: PropTypes.object
};

function AdminFile({ location }) {
  const [isLoggedIn, setIsLoggedIn] = useRecoilState(loginState);
  const [profile, setProfile] = useRecoilState(profileState);
  const [, setConfiguration] = useRecoilState(configurationState);

  const initialize = async () => {
    const result = await checkLoginStatus();
    if (!result) return navigate('/login');
    const { profile, isLoggedIn, configuration } = result;
    setProfile(profile);
    setConfiguration(configuration);
    return setTimeout(() => setIsLoggedIn(isLoggedIn), 1000);
  };

  useEffect(() => {
    // Check the Valid Token
    if (profile?.role?.value === 'Guest') {
      navigate('/guest/booking/');
      return;
    }
    if (isLoggedIn) return;
    initialize();
  }, []);

  // Scroll To Top
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <LoadingPage hide={isLoggedIn} />
      {profile && (
        <Layout showLogoutButton>
          <div className="w-full">
            <AdminNavbar location={location} />
            <div className="pl-4 pr-4 md:pl-8 md:pr-8 xl:pr-16">
              <React.Suspense fallback="">
                <Router>
                  {/* Companies Route */}
                  <PrivateRoute
                    path="/management/company/list"
                    component={ListCompanies}
                    isAllowed={profile?.role?.value === 'Administrator'}
                    navUrl="/management"
                  />
                  <PrivateRoute
                    path="/management/company/create"
                    component={CreateCompany}
                    isAllowed={profile?.role?.value === 'Administrator'}
                    navUrl="/management"
                  />
                  <PrivateRoute
                    path="/management/company/edit/:id"
                    component={EditCompany}
                    isAllowed={profile?.role?.value === 'Administrator'}
                    navUrl="/management"
                  />
                  {/* Accounts Route */}
                  <PrivateRoute
                    path="/management/account/list"
                    component={ListAccounts}
                    isAllowed={
                      profile?.role?.value === 'Administrator' || profile?.role?.value === 'SuperUser'
                    }
                    navUrl="/management"
                  />
                  <PrivateRoute
                    path="/management/account/create"
                    component={CreateAccount}
                    isAllowed={
                      profile?.role?.value === 'Administrator' || profile?.role?.value === 'SuperUser'
                    }
                    navUrl="/management"
                  />
                  <PrivateRoute
                    path="/management/account/edit/:id"
                    component={EditAccount}
                    isAllowed={
                      profile?.role?.value === 'Administrator' || profile?.role?.value === 'SuperUser'
                    }
                    navUrl="/management"
                  />
                  {/* Configuration Route */}
                  <PrivateRoute
                    path="/management/configuration"
                    component={Configuration}
                    isAllowed={profile?.role?.value === 'Administrator'}
                    navUrl="/management"
                  />
                  {/* Customistion Route */}
                  <PrivateRoute
                    path="/management/customisation/list"
                    component={Customisation}
                    isAllowed={
                      profile?.role?.value === 'Administrator' || profile?.role?.value === 'SuperUser'
                    }
                    navUrl="/management"
                  />
                  <PrivateRoute
                    path="/management/customisation/create"
                    component={CreateCustomisation}
                    isAllowed={
                      profile?.role?.value === 'Administrator' || profile?.role?.value === 'SuperUser'
                    }
                    navUrl="/management"
                  />
                  <PrivateRoute
                    path="/management/customisation/edit/:id"
                    component={EditCustomisation}
                    isAllowed={
                      profile?.role?.value === 'Administrator' || profile?.role?.value === 'SuperUser'
                    }
                    navUrl="/management"
                  />
                  {/* Legal Routes */}
                  <PrivateRoute
                    path="/management/legal/list"
                    component={ListLegal}
                    isAllowed={profile?.role?.value === 'Administrator'}
                    navUrl="/management"
                  />
                  <PrivateRoute
                    path="/management/legal/create"
                    component={CreateLegalReport}
                    isAllowed={profile?.role?.value === 'Administrator'}
                    navUrl="/management"
                  />
                  <PrivateRoute
                    path="/management/legal/edit/:id"
                    component={EditLegal}
                    isAllowed={profile?.role?.value === 'Administrator'}
                    navUrl="/management"
                  />
                  {/* System Logs Route */}
                  <PrivateRoute
                    path="/management/systemlog/search"
                    component={ListSystemLogs}
                    isAllowed={profile?.role?.value === 'Administrator'}
                    navUrl="/management"
                  />
                  <PrivateRoute
                    path="/management/systemlog/view/:id"
                    component={ViewSystemLogs}
                    isAllowed={profile?.role?.value === 'Administrator'}
                    navUrl="/management"
                  />
                  <PrivateRoute
                    path="/management/legal/edit/:id"
                    component={EditLegal}
                    isAllowed={profile?.role?.value === 'Administrator'}
                    navUrl="/management"
                  />
                  {/* Users Routes */}
                  <PrivateRoute
                    path="/management/user/list"
                    component={ListUsers}
                    isAllowed={
                      profile?.role?.value === 'Administrator' ||
                      profile?.role?.value === 'Controller' ||
                      profile?.role?.value === 'Manager' ||
                      profile?.role?.value === 'Supervisor' ||
                      profile?.role?.value === 'SuperUser'
                    }
                    navUrl="/management"
                  />
                  <PrivateRoute
                    path="/management/user/create"
                    component={CreateUser}
                    isAllowed={
                      profile?.role?.value === 'Administrator' ||
                      profile?.role?.value === 'Controller' ||
                      profile?.role?.value === 'Manager' ||
                      profile?.role?.value === 'Supervisor' ||
                      profile?.role?.value === 'SuperUser'
                    }
                    navUrl="/management"
                  />
                  <PrivateRoute
                    path="/management/appuser/create"
                    component={CreateAppUser}
                    isAllowed={
                      (profile?.role?.value === 'Administrator' ||
                        profile?.role?.value === 'Controller' ||
                        profile?.role?.value === 'Manager' ||
                        profile?.role?.value === 'Supervisor' ||
                        profile?.role?.value === 'SuperUser') &&
                      profile?.options?.general?.appUsers
                    }
                    navUrl="/management"
                  />
                  <PrivateRoute
                    path="/management/user/edit/:id"
                    component={EditUser}
                    isAllowed={
                      profile?.role?.value === 'Administrator' ||
                      profile?.role?.value === 'Controller' ||
                      profile?.role?.value === 'Manager' ||
                      profile?.role?.value === 'Supervisor' ||
                      profile?.role?.value === 'SuperUser'
                    }
                    navUrl="/management"
                  />
                  {/* Vehicle Type Roues */}
                  <PrivateRoute
                    path="/management/vehicletype/list"
                    component={ListVehicleTypes}
                    isAllowed={profile?.role?.value === 'Administrator'}
                    navUrl="/management"
                  />
                  <PrivateRoute
                    path="/management/vehicletype/create"
                    component={CreateVehicleType}
                    isAllowed={profile?.role?.value === 'Administrator'}
                    navUrl="/management"
                  />
                  <PrivateRoute
                    path="/management/vehicletype/edit/:id"
                    component={EditVehicleType}
                    isAllowed={profile?.role?.value === 'Administrator'}
                    navUrl="/management"
                  />
                  {/* Quote Modifiers Routes */}
                  <PrivateRoute
                    path="/management/quotemodifier/list"
                    component={ListQuoteModifiers}
                    isAllowed={profile?.role?.value === 'Administrator'}
                    navUrl="/management"
                  />
                  <PrivateRoute
                    path="/management/quotemodifier/create"
                    component={CreateQuoteModifiers}
                    isAllowed={profile?.role?.value === 'Administrator'}
                    navUrl="/management"
                  />
                  <PrivateRoute
                    path="/management/quotemodifier/edit/:id"
                    component={EditQuoteModifier}
                    isAllowed={profile?.role?.value === 'Administrator'}
                    navUrl="/management"
                  />
                  {/* Average Fares Routes */}
                  <PrivateRoute
                    path="/management/averagefare/list"
                    component={ListAverageFare}
                    isAllowed={profile?.role?.value === 'Administrator'}
                    navUrl="/management"
                  />
                  <PrivateRoute
                    path="/management/averagefare/create"
                    component={CreateAverageFare}
                    isAllowed={profile?.role?.value === 'Administrator'}
                    navUrl="/management"
                  />
                  <PrivateRoute
                    path="/management/averagefare/edit/:id"
                    component={EditAverageFare}
                    isAllowed={profile?.role?.value === 'Administrator'}
                    navUrl="/management"
                  />
                  {/* Custom Fields Routes */}
                  <PrivateRoute
                    path="/management/custom-fields"
                    component={ListCustomFields}
                    isAllowed={profile?.role?.value === 'Administrator'}
                    navUrl="/management"
                  />
                  <PrivateRoute
                    path="/management/custom-field/create"
                    component={CreateCustomFields}
                    isAllowed={profile?.role?.value === 'Administrator'}
                    navUrl="/management"
                  />
                  <PrivateRoute
                    path="/management/custom-field/edit/:id"
                    component={EditCustomFields}
                    isAllowed={profile?.role?.value === 'Administrator'}
                    navUrl="/management"
                  />
                </Router>
              </React.Suspense>
            </div>
          </div>
        </Layout>
      )}
    </>
  );
}

export default AdminFile;
